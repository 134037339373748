import { useRouter } from "next/router";

import { PriceData } from "data/product/products";
import { saveLastSeenOfferInfo } from "src/api/userApi";
import Cookies from "universal-cookie";

import { useAuth } from "./auth/useAuth";

export const usePriceOfferInfoCheckout = () => {
  const { user, loadingCurrentUser } = useAuth();
  const cookies = new Cookies();
  const router = useRouter();
  const { p } = router.query;
  const KEY_FOR_EXP_DATE_SEEN = "studioCountdownMainCycleOffer_expirationDateSeen";
  const KEY_FOR_LAST_SEEN_OFFER = "studioCountdownMainCycleOffer_lastSeenCurrentOffer";
  const STUDIO_MAIN_CYCLE_OFFER_ID = "studioCountdownMainCycleOffer";
  const offerWaitingPeriodMiliseconds = 1000 * 60 * 60 * 24 * 26; // 26 days in miliseconds
  // This shouldShowForEveryone is set as true whenever we want to skip hiding the offer for everyone. false if we want it to be normal and hide accordingly.
  const shouldShowForEveryone = true;

  const shouldUserSeeOfferCalculation = (priceInfoOffer: PriceData) => {

    // if there is no crossed price applicable, then don't show
    if (!priceInfoOffer.crossedPriceApplicableFrom) {
      return false;
    }
    const offerEndDate = new Date(priceInfoOffer.crossedPriceApplicableFrom ?? "").getTime();
    const now = new Date().getTime();

    // console.log(
    //   "WHOLE PRICEINFO OFFER: now? good ",
    //   priceInfoOffer,
    //   priceInfoOffer.specialOnlyVip,
    //   " p is ? ",
    //   p,
    // );
    if (priceInfoOffer.specialOnlyVip && priceInfoOffer.specialOnlyVip == true) {
      //console.log("CHECKING SPECIALONLY VIP ? ", priceInfoOffer, p);
      if (p == "vip") {
        console.log("SO RETURNING ");
        return true;
      } else {
        localStorage.removeItem("noPriceOffer");
        return false;
      }
    } else {
      ///console.log(" NOO CHECKING SPECIALONLY VIP ? ", priceInfoOffer, p);
    }
    // console.log("the offerEndDate is ", offerEndDate, " and now is ", now);
    // If it's after the offer end date, don't show the offer
    if (now > offerEndDate) {
      return false;
    }

    if (priceInfoOffer.specialOnlyVip) {
      if (p === "vip") {
        return true;
      } else {
        return false;
      }
    }

    // If this is an item (like Collections) where if the discount is applicable, there should not be hidden discounts, then return true.
    if (priceInfoOffer.shouldNotHideDiscount) {
      localStorage.removeItem("noPriceOffer");
      return true;
    }

    if (loadingCurrentUser && !shouldShowForEveryone) {
      localStorage.removeItem("noPriceOffer");
      return false;
    }

    // get info from cookies
    let mainCycleExpDateSeen = parseInt(cookies.get(KEY_FOR_EXP_DATE_SEEN));
    let mainCycleOfferLastSeen = parseInt(cookies.get(KEY_FOR_LAST_SEEN_OFFER));

    // get info from user object
    const offersSeenInfo = user?.offersSeenInfo;
    if (offersSeenInfo && STUDIO_MAIN_CYCLE_OFFER_ID in offersSeenInfo) {
      const userSavedExpDateSeen = offersSeenInfo[STUDIO_MAIN_CYCLE_OFFER_ID].expirationDateSeen;
      const userSavedLastSeenOffer =
        offersSeenInfo[STUDIO_MAIN_CYCLE_OFFER_ID].lastSeenCurrentOffer;

      // Use the dates that are most recent between the cookie and the user object
      if (
        userSavedExpDateSeen > mainCycleExpDateSeen ||
        userSavedLastSeenOffer > mainCycleOfferLastSeen ||
        !mainCycleOfferLastSeen ||
        !mainCycleExpDateSeen
      ) {
        mainCycleExpDateSeen = userSavedExpDateSeen;
        mainCycleOfferLastSeen = userSavedLastSeenOffer;
      } else {
      }
    }
    // If there is no saved info for the offer, set it
    if (!mainCycleExpDateSeen || !mainCycleOfferLastSeen) {
      cookies.set(KEY_FOR_EXP_DATE_SEEN, offerEndDate, { path: "/" });
      cookies.set(KEY_FOR_LAST_SEEN_OFFER, now, { path: "/" });
      saveLastSeenOfferInfo(STUDIO_MAIN_CYCLE_OFFER_ID, offerEndDate, now);

      // Return true to show the offer, since the user hadn't seen it before
      //   console.log("not in cookies, so show it and set it");
      return true;
    }
    //const temporaryExtraOfferToCheck = 1680418799000; // TODO: remove this after the offer is over
    // If the offer end date is the same (or for some reason earlier) than the one saved for the user, then it's still the same cycle of the offer, so show it to the user.
    if (offerEndDate == mainCycleExpDateSeen) {
      // Save the last seen time
      cookies.set(KEY_FOR_LAST_SEEN_OFFER, now, { path: "/" });
      saveLastSeenOfferInfo(STUDIO_MAIN_CYCLE_OFFER_ID, offerEndDate, now);
      return true;
    }
    // otherwise this means we're in a new offer cycle.

    // If the user has seen the offer in the last [waiting period time], don't show it to them.
    // Check if the time of waiting period has passed since the last time the user saw the offer
    let timeShouldPassBeforeShowingOfferAgain =
      mainCycleOfferLastSeen + offerWaitingPeriodMiliseconds;
    // console.log(" now is ", now, "and userOfferLastSeen ", userOfferLastSeen);
    if (timeShouldPassBeforeShowingOfferAgain - now > 0) {
      // If it has not passed, then, don't show the offer.
      // Set a flag in localStorage that the offer was not shown, even if there was an offer, so when we send the request to the backend, we know we should charge the full non-offer price.
      // check url routing for query param "p=vip", if it's there, show the offer

      // This shouldShowForEveryone is set as True whenever we want to skip hiding the offer for everyone.
      if (!shouldShowForEveryone) {
        if (p !== "vip") {
          localStorage.setItem("noPriceOffer", "true");
          return false;
        }
      }
    }
    // else, if it has passed, then show the offer.

    // Save the last seen time.
    cookies.set(KEY_FOR_LAST_SEEN_OFFER, now, { path: "/" });
    // Now save the new offer end date.
    cookies.set(KEY_FOR_EXP_DATE_SEEN, offerEndDate, { path: "/" });
    saveLastSeenOfferInfo(STUDIO_MAIN_CYCLE_OFFER_ID, offerEndDate, now);
    localStorage.removeItem("noPriceOffer");

    return true;
  };

  const getProductPrice = (priceInfoOffer: PriceData, showOffer: boolean) => {
    return showOffer ? priceInfoOffer.price : priceInfoOffer.crossedPrice;
  };

  return {
    shouldUserSeeOfferCalculation,
    getProductPrice,
  };
};
